<template>
  <div>
    <banner
      title="Agenda Penilaian Buku"
      :breadcrumbs="breadcrumbs"
      :icon="require('@/assets/home/decoration/book.svg')"
    />

    <section id="content">
      <div v-if="!$store.state.loadEventPenilaian" class="container">
        <div class="row">
          <div
            v-for="(event, index) in eventPenilaian
              .filter(
                (event) => event.status !== 'draft' && event.mode == 'public'
              )
              .reverse()"
            :key="index"
            class="col-lg-4 col-md-6"
          >
            <AssessmentCard :assessment="event" class="mt-4" />
          </div>
        </div>
      </div>
      <div v-else class="text-center">
        <div
          class="spinner-border"
          style="width: 3rem; height: 3rem;"
          role="status"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div
        v-if="!$store.state.loadEventPenilaian && eventPenilaian.length == 0"
        class="text-center mt-5"
      >
        <img width="60" src="@/assets/not-found.png" class="img-fluid" alt="" />
        <h4 class="text-center mt-2">Data tidak ditemukan.</h4>
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import Banner from "@/components/homepage/Banner.vue";
import AssessmentCard from "@/components/assessment/Card.vue";
moment.locale("id");
export default {
  components: { Banner, AssessmentCard },
  name: "Event Penilain",
  data: () => ({
    breadcrumbs: [
      {
        label: "Beranda",
        url: "/",
      },
      {
        label: "Pendaftaran Penilaian",
        url: "",
      },
    ],
  }),
  computed: {
    ...mapState(["eventPenilaian"]),
  },
  methods: {
    ...mapActions(["fetchAllEventPenilaian"]),
    checkStatusEvent(status) {
      return status == "open" ? "Dibuka" : "Ditutup";
    },
    convertDate(date) {
      return moment(date).format("LL");
    },
  },
  created() {
    this.fetchAllEventPenilaian();
  },
};
</script>
